import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import Privacy from '../sections/Privacy';

export default () => (
  <Layout>
    <Helmet>
      <title>個人情報保護方針</title>
    </Helmet>
    <Privacy />
  </Layout>
);
