import styled from 'styled-components';
import SectionWrapper from '../../styles/SectionWrapper';
import Colors from '../../consts/Colors';
import headerPrivacy from '../../assets/header_privacy.png';
import silhouetteBusiness from '../../assets/silhouette_business.png';

export default styled(SectionWrapper)`
  background-image: linear-gradient(#ebe0ee 0, #d5bfdc 100%);
  /* 
  * footer を下部に位置する設定
  * 全体ラッパーに flexbox の設定をする
  * 子要素で flex: 1; を設定する
  */
  flex: 1;
  h1 {
    background: url(${headerPrivacy}) no-repeat 0 0;
  }
  div.privacy-content {
    font-size: 13px;
    color: ${Colors.textContent};
    margin-top: 30px;
    h2 {
      margin-bottom: 20px;
      margin-left: 23px;
      margin-right: 23px;
    }
    p.sec1 {
      margin-left: 20px;
      margin-right: 20px;
    }
    p.sec2 {
      margin-left: 40px;
      margin-right: 40px;
    }
    a:visited {
      color: ${Colors.textContent};
    }
    a:link {
      color: ${Colors.textContent};
    }
    #return {
      font-size: 18px;
      text-align: center;
      margin-top: 100px;
    }
  }
  /* マウスオーバーでシルエット画像切り替え */
  .silhouette-image {
    background: url(${silhouetteBusiness}) no-repeat 0 0;
  }
  .silhouette-image:hover {
    background-position: 0 bottom;
  }
`;
