import React from 'react';
import { Link } from 'gatsby';
import Container from './style';

const Privacy: React.FC = () => (
  <Container>
    <div className="container">
      <div className="section-content">
        <h1 className="wow fadeInDown">個人情報保護方針</h1>
        <div className="privacy-content">
          <p className="sec1">
            当社は情報サービス業として、個人情報がプライバシーを構成する重要な情報であることを認識し、
            役員・社員一同に個人情報保護の重要性の認識と取組みの徹底をはかることが当社の社会的責務と考え、
            個人情報を取り扱う場合には、個人情報に関する法令を遵守し、次の取り組みを行います。
          </p>
          <h2>1.個人情報の取得・利用について</h2>
          <p className="sec2">
            個人情報を取得する場合は、その利用目的を明確にし、適切な範囲内で取得します。
            ご本人の同意なしに利用目的の達成に必要な範囲を越える取扱いを行いません。
          </p>
          <h2>2.個人情報の提供について</h2>
          <p className="sec2">
            個人情報を第三者に提供する場合にも、法令に特に定められた場合を除き、ご本人にその旨の同意を得た上で行います。
          </p>
          <h2>3.個人情報の管理・保護について</h2>
          <p className="sec2">
            個人情報を取扱う管理責任者を特定します。取扱い担当者を必要最小限に限定するとともに、
            取得または提供された個人情報の漏洩、滅失又は毀損を防止するため、適切な人的・物理的・技術的安全管理措置を講じます。
          </p>
          <h2>4.個人情報の照会・訂正・削除等について</h2>
          <p className="sec2">
            お客様より個人情報の照会・訂正・削除等の要請を受けた場合には、その意思を尊重し、合理的な範囲で必要な対応を行います。
          </p>
          <h2>5.法令・規範の遵守について</h2>
          <p className="sec2">
            個人情報の取り扱いに関する法令、国が定める指針及びその他の規範を遵守します。
          </p>
          <h2>6.問合せ・苦情への対応について</h2>
          <p className="sec2">
            保有する個人情報に関するお問合せや苦情に対して、受付、対応の体制と手順を整備し、迅速に対応します。
          </p>
          <h2>7.個人情報保護管理体制及び仕組みの継続的改善について</h2>
          <p className="sec2">
            社会情勢、技術進歩等内外の諸環境の変化に伴い、個人情報の適切な保護を維持するため個人情報保護マネジメントシステムの定期的な見直しを行い、マネジメントシステムの継続的改善に努めます。
          </p>
          <div id="return">
            <Link to="/">Homeへ</Link>
          </div>
        </div>
      </div>
      <div className="silhouette-container">
        <span className="silhouette-image" />
      </div>
    </div>
  </Container>
);

export default Privacy;
